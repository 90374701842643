/* eslint-disable @typescript-eslint/no-explicit-any */
import NextImage, { ImageLoader, ImageProps } from 'next/image'
import React from 'react'

const storageUrlFragmentBeta = 'cms-api-beta.mgw.cz/'
const storageUrlFragment = 'cms-api.mgw.cz/'

const betaCdn = 'https://contember-beta.imgix.net/'
const prodCdn = 'https://contember.imgix.net/'

function normalizeSrc(src: string) {
	return src[0] === '/' ? src.slice(1) : src
}

const createImgixLoader =
	(root: string): ImageLoader =>
	({ src, width, quality }) => {
		// Demo: https://static.imgix.net/daisy.png?format=auto&fit=max&w=300
		const params = ['auto=format', 'fit=max', 'w=' + width]
		let paramsString = ''
		if (quality) {
			params.push('q=' + quality)
		}
		if (params.length) {
			paramsString = '?' + params.join('&')
		}
		return `${root}${normalizeSrc(src)}${paramsString}`
	}

const loaders = {
	beta: createImgixLoader(betaCdn),
	prod: createImgixLoader(prodCdn),
}

export const ImageSizesContext = React.createContext<string | undefined>(undefined)

export function Image(props: ImageProps) {
	const { src } = props

	const sizes = React.useContext(ImageSizesContext)

	const srcProps = React.useMemo(() => {
		const isGif = /\.gif$/.test(src)
		if (!isGif) {
			let fragmentPosition = src.indexOf(storageUrlFragment)
			if (fragmentPosition >= 0) {
				return {
					src: src.substring(fragmentPosition + storageUrlFragment.length),
					loader: loaders.prod,
				}
			}
			fragmentPosition = src.indexOf(storageUrlFragmentBeta)
			if (fragmentPosition >= 0) {
				return {
					src: src.substring(fragmentPosition + storageUrlFragmentBeta.length),
					loader: loaders.beta,
				}
			}
		}
		return {
			src,
			unoptimized: true,
			loader: undefined,
		}
	}, [src])

	const { width, height, src: _src, ...otherProps } = props

	if (typeof src !== 'string') {
		return null
	}

	const layout = 'layout' in props ? props.layout : undefined

	if ((!width || !height) && layout !== 'fill') {
		return null
	}

	const baseProps = {
		sizes: props.sizes ?? sizes,
		src: srcProps.src as any,
		loader: srcProps.loader ?? loaders.prod,
		unoptimized: props.unoptimized || srcProps.unoptimized || process.env.STORYBOOK === 'true',
	}

	if (layout === 'fill') {
		return <NextImage {...otherProps} {...baseProps} layout="fill" />
	}

	return (
		<NextImage
			{...otherProps}
			{...baseProps}
			layout={layout}
			width={width ?? 1}
			height={height ?? 1}
		/>
	)
}
