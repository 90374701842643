import React from 'react'

function toArray<El extends HTMLElement>(collection: HTMLCollectionOf<El>): Array<El> {
	const result: Array<El> = []

	for (let i = 0; i < collection.length; i++) {
		result.push(collection[i])
	}

	return result
}

export function useAllImagesLoadedInside() {
	const ref = React.useRef<null | HTMLDivElement>(null)
	const [imagesLoaded, setImagesLoaded] = React.useState(false)

	React.useEffect(() => {
		if (!ref.current) return
		const resolveReference: Array<(value: unknown) => void> = []
		const imageElements = toArray(ref.current.getElementsByTagName('img'))
		const promisesArray = imageElements.map((img) => {
			if (!img.complete) {
				return new Promise((resolve) => {
					resolveReference.push(resolve)
					img.addEventListener('load', resolve, { once: true })
				})
			} else return null
		})
		if (promisesArray.length > 0) {
			Promise.all(promisesArray).then(() => {
				setImagesLoaded(true)
			})
		}

		return () => {
			imageElements.forEach((img, index) => {
				img.removeEventListener('load', resolveReference[index])
			})
		}
	}, [ref])

	return [ref, imagesLoaded] as const
}
