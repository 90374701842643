import React from 'react'

const W = 107
const H = 21
const RATIO = W / H

const EM_WIDTH = `${RATIO}em`
const EM_HEIGHT = `1em`

export const ClotinoLogo = React.memo(function ClotinoLogo() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			role="img"
			viewBox={`0 0 ${W} ${H}`}
			width={EM_WIDTH}
			height={EM_HEIGHT}>
			<title>Clotino</title>
			<g fill="none">
				<path
					fill="currentColor"
					d="M14.393 5.752c-1.2 0-1.732-.937-4.131-.937-3.199 0-5.997 2.676-5.997 6.153 0 3.612 2.665 6.153 5.997 6.153 2.665 0 3.198-.936 4.398-.936 1.066 0 1.865.802 1.865 1.872 0 .937-.8 1.606-1.732 2.007-1.2.535-2.799.802-4.664.802C4.398 21 0 17.121 0 11.102c0-5.618 4.798-9.764 10.262-9.764 1.732 0 3.065.267 4.264.668.933.402 1.6 1.07 1.6 1.873.133 1.204-.8 1.873-1.733 1.873zm6.264-2.676c0-1.07.8-1.738 1.866-1.738s1.865.802 1.865 1.738v16.052c0 .936-.8 1.738-1.866 1.738s-1.865-.802-1.865-1.738V3.076zM35.85 7.49c3.864 0 6.93 2.542 6.93 6.688 0 4.147-3.066 6.688-6.93 6.688-3.865 0-6.93-2.541-6.93-6.688 0-4.146 3.065-6.688 6.93-6.688zm0 10.3c1.998 0 3.331-1.471 3.331-3.612 0-2.14-1.332-3.477-3.332-3.477-1.999 0-3.331 1.471-3.331 3.611s1.332 3.478 3.331 3.478zM47.977 7.758V5.885c0-1.07.8-1.872 1.866-1.872.933 0 1.865.802 1.865 1.872v1.873h2.533c.8 0 1.466.669 1.466 1.471 0 .803-.667 1.472-1.466 1.472h-2.532v5.35c0 1.204.4 1.739 1.332 1.739 1.066 0 1.466-.803 2.266-.803s1.332.669 1.332 1.472c0 .802-.8 1.47-1.732 2.006-.8.401-1.732.535-2.665.535-3.066 0-4.265-2.006-4.265-4.548v-5.751h-.933c-.8 0-1.466-.67-1.466-1.472 0-.802.666-1.471 1.466-1.471h.933z"></path>
				<path
					fill="#F74D56"
					d="M61.837 4.28a2.136 2.136 0 002.132-2.14A2.136 2.136 0 0061.837 0a2.136 2.136 0 00-2.132 2.14c0 1.182.954 2.14 2.132 2.14z"></path>
				<path
					fill="currentColor"
					d="M61.837 7.49c1.066 0 1.866.803 1.866 1.74v9.764c0 .936-.933 1.739-1.866 1.739-1.066 0-1.866-.803-1.866-1.74v-9.63c0-.936.933-1.873 1.866-1.873zm9.063 0a1.87 1.87 0 011.865 1.606A5.553 5.553 0 0176.63 7.49c1.466 0 2.798.402 3.731 1.204.933.803 1.466 2.14 1.466 4.013v6.287c0 .936-.933 1.739-1.865 1.739-.933 0-1.866-.803-1.866-1.74v-5.35c0-.936-.133-1.872-.667-2.407a2.96 2.96 0 00-1.732-.535c-2.132 0-2.932 2.006-2.932 3.879v4.547c0 .937-.933 1.74-1.866 1.74-1.066 0-1.866-.803-1.866-1.74V9.363c0-.936.8-1.873 1.866-1.873zm22.122 0c3.865 0 6.93 2.542 6.93 6.688 0 4.147-3.065 6.688-6.93 6.688-3.865 0-6.93-2.541-6.93-6.688 0-4.146 3.065-6.688 6.93-6.688zm0 10.3c1.999 0 3.331-1.471 3.331-3.612 0-2.14-1.332-3.477-3.331-3.477-2 0-3.332 1.471-3.332 3.611s1.333 3.478 3.332 3.478z"></path>
				<path
					fill="#F74D56"
					d="M102.351 18.191c0-1.204.933-2.14 2.132-2.14 1.2 0 2.132.936 2.132 2.14 0 1.204-.932 2.14-2.132 2.14a2.109 2.109 0 01-2.132-2.14z"></path>
			</g>
		</svg>
	)
})
